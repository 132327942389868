.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
  font-size: 75%;
  font-weight: bold;
  margin-bottom: -5px;
}

.processing {
  background-color: black;
}

.formNote {
  margin-top: 10px;
  font-size: 0.8em;
}

.checkboxMultilineIndent {
  text-indent: -10em;
}

.requiredFields {
  color: red;
  font-weight: bold;
}

.alert {
  padding: 0.2rem 1rem;
  margin: 0.5rem 0;
}

.TPFMainHeaderClass {
  color: #165788;
  font-weight: bold;
  font-family: 'myriad-pro-condensed', sans-serif;
  font-weight: 400;
  font-size: 4.3em;
}

.TPFMainSubHeaderClass {
  color: #165788;
  font-weight: bold;
  font-family: 'myriad-pro-condensed', sans-serif;
  font-weight: 700;
  font-size: 2.3em;
  margin-top: -8px;
}
