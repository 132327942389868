.flag-dropdown {
  display: none;
}

.react-tel-input {
}

.phoneInput {
  padding-left: 10px !important;
  font-size: 16.6px !important;
  padding-left: 12px !important;
}
