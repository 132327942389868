body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

.landing {
  position: relative;
  background: url(/static/media/aca.a8b1a5f3.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.trans {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
  font-size: 75%;
  font-weight: bold;
  margin-bottom: -5px;
}

.processing {
  background-color: black;
}

.formNote {
  margin-top: 10px;
  font-size: 0.8em;
}

.checkboxMultilineIndent {
  text-indent: -10em;
}

.requiredFields {
  color: red;
  font-weight: bold;
}

.alert {
  padding: 0.2rem 1rem;
  margin: 0.5rem 0;
}

.TPFMainHeaderClass {
  color: #165788;
  font-weight: bold;
  font-family: 'myriad-pro-condensed', sans-serif;
  font-weight: 400;
  font-size: 4.3em;
}

.TPFMainSubHeaderClass {
  color: #165788;
  font-weight: bold;
  font-family: 'myriad-pro-condensed', sans-serif;
  font-weight: 700;
  font-size: 2.3em;
  margin-top: -8px;
}

.m0 {
  margin: 0px !important;
}
.m1 {
  margin: 1px !important;
}
.m2 {
  margin: 2px !important;
}
.m3 {
  margin: 3px !important;
}
.m4 {
  margin: 4px !important;
}
.m5 {
  margin: 5px !important;
}
.m6 {
  margin: 6px !important;
}
.m7 {
  margin: 7px !important;
}
.m8 {
  margin: 8px !important;
}
.m9 {
  margin: 9px !important;
}
.m10 {
  margin: 10px !important;
}
.m11 {
  margin: 11px !important;
}
.m12 {
  margin: 12px !important;
}
.m13 {
  margin: 13px !important;
}
.m14 {
  margin: 14px !important;
}
.m15 {
  margin: 15px !important;
}
.m16 {
  margin: 16px !important;
}
.m17 {
  margin: 17px !important;
}
.m18 {
  margin: 18px !important;
}
.m19 {
  margin: 19px !important;
}
.m20 {
  margin: 20px !important;
}
.m30 {
  margin: 30px !important;
}
.m40 {
  margin: 40px !important;
}
.m50 {
  margin: 50px !important;
}
.m60 {
  margin: 60px !important;
}
.m70 {
  margin: 70px !important;
}
.m80 {
  margin: 80px !important;
}
.m90 {
  margin: 90px !important;
}
.m100 {
  margin: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}
.mt1 {
  margin-top: 1px !important;
}
.mt2 {
  margin-top: 2px !important;
}
.mt3 {
  margin-top: 3px !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt6 {
  margin-top: 6px !important;
}
.mt7 {
  margin-top: 7px !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt9 {
  margin-top: 9px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt11 {
  margin-top: 11px !important;
}
.mt12 {
  margin-top: 12px !important;
}
.mt13 {
  margin-top: 13px !important;
}
.mt14 {
  margin-top: 14px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mt17 {
  margin-top: 17px !important;
}
.mt18 {
  margin-top: 18px !important;
}
.mt19 {
  margin-top: 19px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mt80 {
  margin-top: 80px !important;
}
.mt90 {
  margin-top: 90px !important;
}
.mt100 {
  margin-top: 100px !important;
}
.ml0 {
  margin-left: 0px !important;
}
.ml1 {
  margin-left: 1px !important;
}
.ml2 {
  margin-left: 2px !important;
}
.ml3 {
  margin-left: 3px !important;
}
.ml4 {
  margin-left: 4px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml6 {
  margin-left: 6px !important;
}
.ml7 {
  margin-left: 7px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.ml9 {
  margin-left: 9px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml11 {
  margin-left: 11px !important;
}
.ml12 {
  margin-left: 12px !important;
}
.ml13 {
  margin-left: 13px !important;
}
.ml14 {
  margin-left: 14px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml16 {
  margin-left: 16px !important;
}
.ml17 {
  margin-left: 17px !important;
}
.ml18 {
  margin-left: 18px !important;
}
.ml19 {
  margin-left: 19px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml50 {
  margin-left: 50px !important;
}
.ml60 {
  margin-left: 60px !important;
}
.ml70 {
  margin-left: 70px !important;
}
.ml80 {
  margin-left: 80px !important;
}
.ml90 {
  margin-left: 90px !important;
}
.ml100 {
  margin-left: 100px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}
.mb1 {
  margin-bottom: 1px !important;
}
.mb2 {
  margin-bottom: 2px !important;
}
.mb3 {
  margin-bottom: 3px !important;
}
.mb4 {
  margin-bottom: 4px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb6 {
  margin-bottom: 6px !important;
}
.mb7 {
  margin-bottom: 7px !important;
}
.mb8 {
  margin-bottom: 8px !important;
}
.mb9 {
  margin-bottom: 9px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb11 {
  margin-bottom: 11px !important;
}
.mb12 {
  margin-bottom: 12px !important;
}
.mb13 {
  margin-bottom: 13px !important;
}
.mb14 {
  margin-bottom: 14px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb16 {
  margin-bottom: 16px !important;
}
.mb17 {
  margin-bottom: 17px !important;
}
.mb18 {
  margin-bottom: 18px !important;
}
.mb19 {
  margin-bottom: 19px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.mb70 {
  margin-bottom: 70px !important;
}
.mb80 {
  margin-bottom: 80px !important;
}
.mb90 {
  margin-bottom: 90px !important;
}
.mb100 {
  margin-bottom: 100px !important;
}

.mr0 {
  margin-right: 0px !important;
}
.mr1 {
  margin-right: 1px !important;
}
.mr2 {
  margin-right: 2px !important;
}
.mr3 {
  margin-right: 3px !important;
}
.mr4 {
  margin-right: 4px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr6 {
  margin-right: 6px !important;
}
.mr7 {
  margin-right: 7px !important;
}
.mr8 {
  margin-right: 8px !important;
}
.mr9 {
  margin-right: 9px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr11 {
  margin-right: 11px !important;
}
.mr12 {
  margin-right: 12px !important;
}
.mr13 {
  margin-right: 13px !important;
}
.mr14 {
  margin-right: 14px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr16 {
  margin-right: 16px !important;
}
.mr17 {
  margin-right: 17px !important;
}
.mr18 {
  margin-right: 18px !important;
}
.mr19 {
  margin-right: 19px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mr60 {
  margin-right: 60px !important;
}
.mr70 {
  margin-right: 70px !important;
}
.mr80 {
  margin-right: 80px !important;
}
.mr90 {
  margin-right: 90px !important;
}
.mr100 {
  margin-right: 100px !important;
}

.p0 {
  margin: 0px !important;
}
.p1 {
  margin: 1px !important;
}
.p2 {
  margin: 2px !important;
}
.p3 {
  margin: 3px !important;
}
.p4 {
  margin: 4px !important;
}
.p5 {
  margin: 5px !important;
}
.p6 {
  margin: 6px !important;
}
.p7 {
  margin: 7px !important;
}
.p8 {
  margin: 8px !important;
}
.p9 {
  margin: 9px !important;
}
.p10 {
  margin: 10px !important;
}
.p11 {
  margin: 11px !important;
}
.p12 {
  margin: 12px !important;
}
.p13 {
  margin: 13px !important;
}
.p14 {
  margin: 14px !important;
}
.p15 {
  margin: 15px !important;
}
.p16 {
  margin: 16px !important;
}
.p17 {
  margin: 17px !important;
}
.p18 {
  margin: 18px !important;
}
.p19 {
  margin: 19px !important;
}
.p20 {
  margin: 20px !important;
}
.p30 {
  margin: 30px !important;
}
.p40 {
  margin: 40px !important;
}
.p50 {
  margin: 50px !important;
}
.p60 {
  margin: 60px !important;
}
.p70 {
  margin: 70px !important;
}
.p80 {
  margin: 80px !important;
}
.p90 {
  margin: 90px !important;
}
.p100 {
  margin: 100px !important;
}

.pt0 {
  padding-top: 0px !important;
}
.pt1 {
  padding-top: 1px !important;
}
.pt2 {
  padding-top: 2px !important;
}
.pt3 {
  padding-top: 3px !important;
}
.pt4 {
  padding-top: 4px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt6 {
  padding-top: 6px !important;
}
.pt7 {
  padding-top: 7px !important;
}
.pt8 {
  padding-top: 8px !important;
}
.pt9 {
  padding-top: 9px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt11 {
  padding-top: 11px !important;
}
.pt12 {
  padding-top: 12px !important;
}
.pt13 {
  padding-top: 13px !important;
}
.pt14 {
  padding-top: 14px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt16 {
  padding-top: 16px !important;
}
.pt17 {
  padding-top: 17px !important;
}
.pt18 {
  padding-top: 18px !important;
}
.pt19 {
  padding-top: 19px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pt40 {
  padding-top: 40px !important;
}
.pt50 {
  padding-top: 50px !important;
}
.pt60 {
  padding-top: 60px !important;
}
.pt70 {
  padding-top: 70px !important;
}
.pt80 {
  padding-top: 80px !important;
}
.pt90 {
  padding-top: 90px !important;
}
.pt100 {
  padding-top: 100px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.pl1 {
  padding-left: 1px !important;
}
.pl2 {
  padding-left: 2px !important;
}
.pl3 {
  padding-left: 3px !important;
}
.pl4 {
  padding-left: 4px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pl6 {
  padding-left: 6px !important;
}
.pl7 {
  padding-left: 7px !important;
}
.pl8 {
  padding-left: 8px !important;
}
.pl9 {
  padding-left: 9px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl11 {
  padding-left: 11px !important;
}
.pl12 {
  padding-left: 12px !important;
}
.pl13 {
  padding-left: 13px !important;
}
.pl14 {
  padding-left: 14px !important;
}
.pl15 {
  padding-left: 15px !important;
}
.pl16 {
  padding-left: 16px !important;
}
.pl17 {
  padding-left: 17px !important;
}
.pl18 {
  padding-left: 18px !important;
}
.pl19 {
  padding-left: 19px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pl50 {
  padding-left: 50px !important;
}
.pl60 {
  padding-left: 60px !important;
}
.pl70 {
  padding-left: 70px !important;
}
.pl80 {
  padding-left: 80px !important;
}
.pl90 {
  padding-left: 90px !important;
}
.pl100 {
  padding-left: 100px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}
.pb1 {
  padding-bottom: 1px !important;
}
.pb2 {
  padding-bottom: 2px !important;
}
.pb3 {
  padding-bottom: 3px !important;
}
.pb4 {
  padding-bottom: 4px !important;
}
.pb5 {
  padding-bottom: 5px !important;
}
.pb6 {
  padding-bottom: 6px !important;
}
.pb7 {
  padding-bottom: 7px !important;
}
.pb8 {
  padding-bottom: 8px !important;
}
.pb9 {
  padding-bottom: 9px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb11 {
  padding-bottom: 11px !important;
}
.pb12 {
  padding-bottom: 12px !important;
}
.pb13 {
  padding-bottom: 13px !important;
}
.pb14 {
  padding-bottom: 14px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb16 {
  padding-bottom: 16px !important;
}
.pb17 {
  padding-bottom: 17px !important;
}
.pb18 {
  padding-bottom: 18px !important;
}
.pb19 {
  padding-bottom: 19px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.pb40 {
  padding-bottom: 40px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.pb60 {
  padding-bottom: 60px !important;
}
.pb70 {
  padding-bottom: 70px !important;
}
.pb80 {
  padding-bottom: 80px !important;
}
.pb90 {
  padding-bottom: 90px !important;
}
.pb100 {
  padding-bottom: 100px !important;
}

.pr0 {
  padding-right: 0px !important;
}
.pr1 {
  padding-right: 1px !important;
}
.pr2 {
  padding-right: 2px !important;
}
.pr3 {
  padding-right: 3px !important;
}
.pr4 {
  padding-right: 4px !important;
}
.pr5 {
  padding-right: 5px !important;
}
.pr6 {
  padding-right: 6px !important;
}
.pr7 {
  padding-right: 7px !important;
}
.pr8 {
  padding-right: 8px !important;
}
.pr9 {
  padding-right: 9px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr11 {
  padding-right: 11px !important;
}
.pr12 {
  padding-right: 12px !important;
}
.pr13 {
  padding-right: 13px !important;
}
.pr14 {
  padding-right: 14px !important;
}
.pr15 {
  padding-right: 15px !important;
}
.pr16 {
  padding-right: 16px !important;
}
.pr17 {
  padding-right: 17px !important;
}
.pr18 {
  padding-right: 18px !important;
}
.pr19 {
  padding-right: 19px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pr30 {
  padding-right: 30px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pr50 {
  padding-right: 50px !important;
}
.pr60 {
  padding-right: 60px !important;
}
.pr70 {
  padding-right: 70px !important;
}
.pr80 {
  padding-right: 80px !important;
}
.pr90 {
  padding-right: 90px !important;
}
.pr100 {
  padding-right: 100px !important;
}

.font5 {
  font-size: 5px !important !important;
}
.font10 {
  font-size: 10px !important !important;
}
.font15 {
  font-size: 15px !important !important;
}
.font20 {
  font-size: 20px !important !important;
}
.font25 {
  font-size: 25px !important !important;
}
.font30 {
  font-size: 30px !important !important;
}
.font35 {
  font-size: 35px !important !important;
}
.font40 {
  font-size: 40px !important !important;
}
.font45 {
  font-size: 45px !important !important;
}
.font50 {
  font-size: 50px !important !important;
}

.grey {
  color: lightgrey !important;
}

.flag-dropdown {
  display: none;
}

.react-tel-input {
}

.phoneInput {
  padding-left: 10px !important;
  font-size: 16.6px !important;
  padding-left: 12px !important;
}

.popup-content !important {
  background-color: black;
}

